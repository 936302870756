<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <button class="close-button" @click="closeModal">x</button>
    <div class="modal-content">
      <h2>{{ title }}</h2>
      <div class="modal-text">
        <pre>{{ text }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    title: String,
    text: String
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: #f5efeb;
  padding: 50px;
  border-radius: 8px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  font-family: 'Times New Roman', Times, serif;
  box-sizing: border-box;
}

/* Title styles */
.modal-content h2 {
  text-align: center; /* Center the title text */
  font-size: 1.4em; /* Increase the size of the title */
  margin-top: 0px; /* Adjust top margin as needed */
  margin-bottom: 3em; /* Space below the title */
}

.modal-text pre {
  white-space: pre-wrap; /* Allows text within <pre> to wrap at natural breaks */
  word-break: break-word; /* Ensures words only break at natural breaking points within the container */
  overflow-wrap: break-word; /* Prefer breaking words at normal word breaks */
  font-size: 0.8em; /* Increase the size of the text */
}


/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  background: none;
}
</style>
