import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/tailwind.css';
import { auth } from './firebase/firebaseConfig';

Vue.config.productionTip = false;

let app;

// Initialize the Vue instance once the authentication state is known
auth.onAuthStateChanged(user => {
  console.log(user);
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app');
  }
});
