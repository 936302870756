<template>
  <div class="main-container">

    <subscription-modal
      :visible="!isSubscribed && showModal"
      :products="products"
      @requestCloseModal="closeModal"
    ></subscription-modal>

    <div class="mobile-header">
      <div class="logo">
        <img src="@/assets/logo-qj.jpg" alt="logo" style="border-radius: 20%; width: 50px; height: 50px;">
      </div>
      <button @click="signOut" class="disconnect-button-mobile">Se déconnecter</button>
    </div>
    <div class="previous-searches" >
      <div class="logo">
        <img src="@/assets/logo-qj.jpg" alt="logo" style="width: 110px; height: 90px;">
      </div>
      <transition name="fade">
        <div v-if="selectedJuridiction === 'cassation'">
          <div class="scrollable-checkbox-container">
            <div v-for="chambre in chambres" :key="chambre.value" class="form-check">
              <input type="checkbox" :id="chambre.value" class="form-check-input" :value="chambre.value" v-model="selectedChambres">
              <label :for="chambre.value" class="form-check-label"><strong>{{ chambre.label }}</strong></label>
            </div>
          </div>
        </div>
      </transition>
      <div>
        <h5 style="color: white; font-family: 'Times New Roman', Times, serif; margin-top: 7px"></h5>
      </div>
      <div class="buttons-class">
    <!-- Show 'Manage Subscription' only if subscribed -->
        <button v-if="subscribed && !trialing" id="gerer" class="disconnect-button" @click="openCustomerPortal" :disabled="isLoading">
          Gérer mon abonnement
        </button>

        <button v-if="trialing" id="gerer" class="disconnect-button" :disabled="isLoading">
          Période d'essai en cours
        </button>

        <!-- Show 'Upgrade Plan' only if not subscribed -->
        <button v-if="!subscribed && !trialing" @click="checkUserSubscriptionStatus" id="sub-but" class="disconnect-button" style="align-self: center;">
          Passer au plan supérieur
        </button>

        <!-- Always show 'Sign Out' button -->
        <button @click="signOut" id="disco" class="disconnect-button" style="align-self: center; margin-top: auto; margin-bottom: 30px;">
          Se déconnecter
        </button>
      </div>
    </div>

    <!-- Div for the Search Form -->
    <div class = "scrollable-container">
      <div class="search-box">
        <div class="select-container">
          <select v-model="selectedJuridiction" class="form-chambre" required>
            <option disabled value="">Choisissez une juridiction</option>
            <option value="cassation">Judiciaire</option>
            <option value="cetat">Administrative</option>
          </select>
        </div>
        <form @submit.prevent="submitSearch" class="search-form">
          <div class="input-container">
            <textarea v-model="searchQuery" :class="{'error-input': searchError}" placeholder="Posez votre problème de droit sous forme de question, et notre système identifiera pour vous les décisions les plus pertinentes." autofocus class="search-input" rows="4" cols="50"></textarea>

            <div class="checkboxes-and-date-container">
              <!-- Multiple select dropdown for solutions -->
              <div id="check-container" class="checkboxes-container bg-light mb-3 p-2 rounded" style="max-height: 200px; overflow-y: auto; display: none">
                <div class="title-checkboxes">
                  <p><strong>Filtrer par solution</strong></p>
                </div>
                <div v-for="option in options" :key="option.value" class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    :id="`select-filter-${option.value}`"
                    type="checkbox"
                    :value="option.value"
                    v-model="selectedOptions"
                  >
                  <label :for="`select-filter-${option.value}`" class="form-check-label">{{ option.text }}</label>
                </div>
              </div>
              <!-- Date range selection -->
              <div id = "date-container" class="date-range-selection bg-light rounded">
                <div class="date-input-container">
                  <label for="start-date" class="form-label"><strong>Début de période</strong></label>
                  <input type="date" id="start-date" class="form-control" v-model="start_date">
                </div>
                <div class="date-input-container" id="input-2">
                  <label for="end-date" class="form-label"><strong>Fin de période</strong></label>
                  <input type="date" id="end-date" class="form-control" v-model="end_date">
                </div>
              </div>
            </div>

          </div>
          <button type="submit" class="search-button" :class="{ 'loading': isLoading }">
            {{ isLoading ? searchProgressMessage : 'Trouver une decision de justice...' }}
          </button>

          <div v-if="searchError" class="error-message">{{ errorMessage }}</div>
          <transition name="fade">
            <button v-if="!isLoading && searchQuery === ''" @click="submitExampleQuery" style="background-color: #f4f4f4;color: black;border: none;padding: 10px 15px;border-radius: 50px; /* Increased to make the button very rounded */cursor: pointer;font-size: 12px;margin-top: 10px;" @mouseover="$event.target.style.backgroundColor = 'lightgray'" @mouseout="$event.target.style.backgroundColor = '#f4f4f4'">Essayez avec: "Quelles sont les actions en responsabilité que les actionnaires minoritaires d'une société cotée peuvent engager contre les dirigeants pour abus de pouvoir ou gestion abusive?"</button>
          </transition>
        </form>
      </div>
      <div class="search-results">
        <transition-group name="list" tag="ul">
          <li v-for="(result, index) in paginatedResults" :key="index" class="result-item">
            <h6 class="result-title"><span class="result-index">{{ (currentPage - 1) * pageSize + index + 1 }}.</span> {{ result.title }}</h6>
            <h6 class="result-link" id="titles"><em>{{ result.actual_title }}</em></h6>
            <!-- Toggle button -->
            <button class="summary-toggle-button" @click="toggleSummary(result.ID_judi)">
              <span v-if="activeCaseId !== result.ID_judi"><strong>Voir résumé d'arrêt</strong> <span class="small-arrow">▼</span></span> <!-- Unicode down arrow -->
              <span v-else><strong>Voir résumé de décision</strong> <span class="small-arrow">▼</span></span> <!-- Unicode up arrow -->
            </button>
            <!-- Conditionally render the case summary -->
            <div v-if="activeCaseId === result.ID_judi" class="case-details">
              <div v-if="caseSummaries[result.ID_judi]?.faitsEtProcedure?.length > 5">
                <p><strong>Faits et Procédure:</strong> {{ caseSummaries[result.ID_judi].faitsEtProcedure }}</p>
              </div>
              <p><strong>Moyens:</strong> {{ caseSummaries[result.ID_judi].moyens }}</p>
              <div v-if="caseSummaries[result.ID_judi].decision !== 'NON'">
                <p><strong>Réponse de la cour:</strong> {{ caseSummaries[result.ID_judi].decision }}</p>
              </div>
              <p><strong>Commentaire:</strong> {{ caseSummaries[result.ID_judi].commentaire }}</p>
              <a v-if="caseSummaries[result.ID_judi]?.texte"
                href="#"
                @click.prevent="openTextModal(result.ID_judi, result.actual_title)">
                <u><strong>Voir la décision complète:</strong> {{ result.actual_title }}</u>
              </a>
              <a
                v-else
                :href="createLink(result)"
                class="result-link"
                target="_blank"
              >
                Voir la décision complète: {{ result.actual_title }}
              </a>
              <div v-if="result.contested_number !== 'NON' && result.contested_jurisdiction !== 'NON'" class="contested-case-card">
                <p><strong>Décision contestée:</strong> {{ result.contested_number }} - {{ result.contested_jurisdiction }}</p>
              </div>
            </div>
          </li>
        </transition-group>
        <TextModal
            :isVisible="showTextModal"
            :title="modalTitle"
            :text="modalText"
            @close="closeTextModal"
          />
        <div class="pagination-controls" v-if="searchResults.length > 0">
          <button @click="changePage(-1)" :disabled="currentPage <= 1">Précédent</button>
          <span>{{ currentPage }} sur {{ totalPages }}</span>
          <button @click="changePage(1)" :disabled="currentPage >= totalPages">Suivant</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SubscriptionModal from './SubscriptionModal.vue';
import TextModal from './TextModal.vue';
import { auth } from "@/firebase/firebaseConfig";
import { logout } from '@/firebase/auth';
import { db } from "@/firebase/firebaseConfig";
import { collection, query, where, doc, getDocs, getDoc } from 'firebase/firestore';
import { getFirebaseToken } from '@/services/api';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const createPortalLink = httpsCallable(functions, 'createStripeCustomerPortalLink');

export default {

  components: {
    SubscriptionModal,
    TextModal,
  },

  data() {
    return {
      isSubscribed: false,
      showModal: [false, false],
      chambres: [
        { label: 'Chambre commerciale', value: 'commerciale' },
        { label: 'Chambre civile 1', value: 'civile 1' },
        { label: 'Chambre civile 2', value: 'civile 2' },
        { label: 'Chambre civile 3', value: 'civile 3' },
        { label: 'Chambre criminelle', value: 'criminelle' },
        { label: 'Chambre sociale', value: 'sociale' }
      ],
      selectedChambres: [],
      dataCategories: [],
      selectedJuridiction: '',
      searchQuery: '',
      lawText: '',
      lawSuggestions: [], // Add this line to store suggestions
      searchResults: [],
      selectedOptions: [], // Array to store selected options
      selectedLaws: [],
      products: [],
      options: [ // Array of options for the dropdown
        { value: 'rejet', text: 'Rejet' },
        { value: 'cassation', text: 'Cassation' },
        { value: 'cassation partielle', text: 'Cassation partielle' },
        { value: 'désistement', text: 'Désistement' },
        { value: 'irrecevabilité', text: 'Irrecevabilité' },
        { value: 'non-lieu à statuer', text: 'Non-lieu à statuer' },
        { value: 'rectification erreur matérielle', text: 'Rectification erreur matérielle' }
      ],
      start_date: null, // Initialize startDate
      end_date: null, // Initialize endDate
      isLoading: false, // Add this line
      searchProgressMessage: '',
      progressMessages: [
        'Notre IA navigue à travers les différentes décisions...',
        'Notre IA lit les décisions les plus pertinentes...',
        'Presque terminé...'
      ],
      currentProgressIndex: 0,
      currentPage: 1, // Initialize current page
      pageSize: 10, // Results per page
      totalPages: 5,
      caseSummaries: {},
      showDetails: {},
      activeCaseId: null,
      showErrorAnimation: false, // Control the display of an error animation
      errorMessage: '',
      searchError: false,
      subscribed: true,
      trialing: false,
      showTextModal: false,
      modalText: '',
      modalTitle: '',
      isArticle: false,
    };
  },

  mounted() {
    // console.log(process.env.stripe_live_key_secret)
    // this.checkUserSubscriptionStatus(); // Call the check subscription function when component mounts
    // this.fetchProducts();
    this.fetchSubscriptionStatus();
  },

  computed: {
    paginatedResults() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.searchResults.slice(start, start + this.pageSize);
    },
  },

  methods: {

    startSearchProgress() {
      this.currentProgressIndex = 0;
      this.searchProgressMessage = this.progressMessages[this.currentProgressIndex];
      const interval = setInterval(() => {
        this.currentProgressIndex++;
        if (this.currentProgressIndex >= this.progressMessages.length) {
          clearInterval(interval); // Stop the interval when all messages have been displayed
        } else {
          this.searchProgressMessage = this.progressMessages[this.currentProgressIndex];
        }
      }, 3000); // Change message every 3 seconds
    },

    completeSearch() {
      clearInterval(this.progressInterval); // Ensure to clear the interval on search completion
      this.searchProgressMessage = ''; // Clear or set to a completion message
    },

    async openCustomerPortal() {
      try {
        const userId = auth.currentUser.uid;  // Get the current user's UID

        // Reference the customer document
        const customerDocRef = doc(db, `customers/${userId}`);
        console.log('Customer Doc Ref:', customerDocRef);
        console.log('Customer Doc Ref:', customerDocRef.stripeId);
        console.log('Customer Doc Ref:', userId);

        const docSnap = await getDoc(customerDocRef);
        if (docSnap.exists()) {
          const customerData = docSnap.data();
          console.log('Customer Data:', customerData);
          const stripeCustomerId = customerData.stripeId;

          if (stripeCustomerId) {
            console.log('Stripe Customer ID:', stripeCustomerId);

            // Create the portal link
            const response = await createPortalLink({ customerId: stripeCustomerId });
            window.location.href = response.data.url;
          } else {
            console.error('Stripe Customer ID not found in the document');
          }
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error opening customer portal:', error);
      }
    },

     async fetchProducts() {
        try {
          console.log('Fetching products...');
          const dbRef = collection(db, "products");
          const activeProductsQuery = query(dbRef, where("active", "==", true));
          const snapshot = await getDocs(activeProductsQuery);
          this.products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), prices: [] })); // Assuming each product might have nested prices which you would fetch similarly
          this.fetchProductPrices();
        } catch (error) {
          console.error("Failed to fetch products:", error);
        }
      },

      closeModal() {
        this.showModal = [false, false];
        console.log('Modal closed');
        // Add any additional cleanup or checks here
      },

    async fetchSubscriptionStatus() {
      if (!auth.currentUser) {
        this.subscriptionStatus = "User not authenticated";
        this.loading = false;
        return;
      }

      const userId = auth.currentUser.uid;
      const subscriptionsRef = collection(db, `customers/${userId}/subscriptions`);
      const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          // Assuming the first active/trialing subscription is the current one
          const subscription = querySnapshot.docs[0].data();
          this.subscriptionStatus = subscription.status;
          this.trialing = subscription.status === "trialing";
        } else {
          this.subscribed = false;
          this.subscriptionStatus = "No active subscription";
        }
      } catch (error) {
        this.subscribed = false;
        this.subscriptionStatus = "Error fetching subscription";
      } finally {
        this.loading = false;
      }
    },

    showProductModal(index) {
      this.showModal = this.showModal.map((val, i) => i === index); // Set true only for the clicked index
    },

    async fetchProductPrices() {
      console.log('Fetching prices...');
      // Assuming each product may have nested prices that need individual fetching
      const promises = this.products.map(async (product) => {
        const pricesRef = collection(db, "products", product.id, "prices");
        const pricesSnap = await getDocs(pricesRef);
        product.prices = pricesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      });

      await Promise.all(promises);
      this.products = [...this.products]; // This triggers reactivity updates for prices
    },

     async checkUserSubscriptionStatus() {
      console.log('Checking subscription status...');
      const userId = auth.currentUser.uid;
      const subscriptionsRef = collection(db, `customers/${userId}/subscriptions`);
      const q = query(subscriptionsRef, where('status', 'in', ['active', 'trialing']));

      try {
        const querySnapshot = await getDocs(q);
        this.showModal = [querySnapshot.empty, querySnapshot.empty]; // Show the modal if no active/trialing subscription
        this.fetchProducts();
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        this.showModal = true;
      }
    },

    submitExampleQuery() {
      this.searchQuery = "Quelles sont les actions en responsabilité que les actionnaires minoritaires d'une société cotée peuvent engager contre les dirigeants pour abus de pouvoir ou gestion abusive?";
      this.selectedJuridiction = 'cassation';
      this.$nextTick(() => {
        this.submitSearch();
      });
    },

    fetchLawSuggestions() {
      if (this.lawText.length > 2) { // Only fetch suggestions if there are more than 2 characters
        axios.get(`https://flask-backend-app.azurewebsites.net/suggest?query=${encodeURIComponent(this.lawText)}`)
          .then(response => {
            console.log(response)
            console.log('Doing')
            this.lawSuggestions = response.data; // Assuming the response is an array of strings
          })
          .catch(error => {
            console.error('Error fetching law suggestions:', error);
          });
      } else {
        this.lawSuggestions = []; // Clear suggestions if the input is too short
      }
    },

    selectLawSuggestion(suggestion) {
      if (!this.selectedLaws.includes(suggestion)) {
        this.selectedLaws.push(suggestion); // Add the selected suggestion to the array
        console.log(this.selectedLaws)
      }
      this.lawText = ''; // Clear the input field after selection to allow new entries
      this.lawSuggestions = []; // Clear the suggestions list
    },
    removeSelectedLaw(law) {
      this.selectedLaws = this.selectedLaws.filter(selected => selected !== law); // Remove the law from the selected list
    },

    formatResult(result) {
      return `ID: ${result.ID}, Index: ${result.index}`;
    },

    createLink(result) {
      if (result.ID.includes('TEXT')) {
        return `https://www.legifrance.gouv.fr/ceta/id/${result.ID}`;
      } else {
        return `https://www.courdecassation.fr/decision/${result.ID_judi}`;
      }
    },

    openTextModal(caseId, title) {
      this.showTextModal = true;
      this.modalText = this.caseSummaries[caseId].texte;
      this.modalTitle = title;
    },

    closeTextModal() {
      this.showTextModal = false;
    },

    toggleSummary(clickedCaseId) {
      if (this.activeCaseId === clickedCaseId) {
        // If the same ID is clicked again, collapse it
        this.activeCaseId = null;
      } else {
        // Set the new ID and fetch data if necessary
        this.activeCaseId = clickedCaseId;
        if (!this.caseSummaries[clickedCaseId]) {
          const docRef = doc(db, 'cases', clickedCaseId);
          getDoc(docRef)
            .then(docSnap => {
              if (docSnap.exists()) {
                this.$set(this.caseSummaries, clickedCaseId, docSnap.data());
              } else {
                console.log('No case summary found for:', clickedCaseId);
              }
            })
            .catch(error => {
              console.error('Error fetching case summary:', error);
            });
        }
      }
    },

    async scrollToFirstResult() {
      await this.$nextTick();
      // const firstResultElement = this.$refs.firstResult;
      const container = document.querySelector('.scrollable-container');
      const firstResultElement = container.querySelector('.result-item');
      if (firstResultElement) {
        const elementPosition = firstResultElement.getBoundingClientRect().top + container.scrollTop - container.getBoundingClientRect().top;
        container.scroll({ top: elementPosition - 50, behavior: 'smooth' });
      }
    },

    async submitSearch() {

      if (this.selectedChambres.length === 0) {
        // If no chambre is selected, default to searching all chambres
        this.dataCategories = []; // Copies all chambres into selectedChambres
      } else {
        this.dataCategories = this.selectedChambres; // Use the selected chambres
      }

      if (this.selectedJuridiction.length === 0) {
        alert('Veuillez choisir une juridiction avant de lancer la recherche.');
        return; // Stop the function if no juridiction is chosen
      }

      this.searchResults = [];
      this.currentPage = 1;
      const wordCount = this.searchQuery.trim().split(/\s+/).length;
      if (wordCount < 1 || wordCount > 500) {
        alert('Entrez une description entre 1 et 500 mots.');
        return;
      }

      this.isLoading = true;
      this.startSearchProgress();
      this.searchError = false;  // Reset the error state before each search
      this.errorMessage = '';

      // let endpoint;

      let endpoint; // Declare the variable outside the switch block
      switch (this.selectedJuridiction) {
        case 'cassation':
          endpoint = "search_global"; // Assign the value within the switch block
          break;
        case 'cetat':
          endpoint = "search_cetat"; // Assign the value within the switch block
          break;
        // Consider adding default case to handle other possibilities or errors
        default:
          endpoint = "default_endpoint"; // Default endpoint or handle error
          break;
      }

      const token = await getFirebaseToken();  // Get the Firebase Auth token

      const keywords = ['loi', 'lois', 'articles', 'article'];

      // Check if searchQuery contains any of the keywords
      this.isArticle = keywords.some(keyword => this.searchQuery.includes(keyword));
      console.log('Is Article:', this.isArticle);
      if (!this.searchQuery.trim().endsWith('?')) {
    this.searchQuery += '?';
}
      this.searchQuery = this.searchQuery.replace(/\s+\?/g, '?');
      console.log('Search Query:', this.searchQuery);

      try {
        const response = await axios.post(`https://api.query-juriste.com/api/${endpoint}`, {
          query: this.searchQuery,
          solution: this.selectedOptions,
          text_filters: this.selectedLaws,
          start_date: this.start_date, // Include the start date
          end_date: this.end_date, // Include the end date
          data_categories: this.dataCategories,
          isArticle: this.isArticle,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`  // Prepare the Authorization header
          }
        });

        // Check if the necessary data is present in the response before processing
        if (response.data && Array.isArray(response.data.IDs) && Array.isArray(response.data.Questions)) {
          console.log(response.data);
          this.searchResults = response.data.IDs.map((ID, idx) => ({
            ID: ID,
            title: response.data.Questions[idx] || "Untitled Decision",
            ID_judi: response.data.IDs_judi[idx],
            actual_title: response.data.titles[idx],
            contested_number: endpoint !== "search_cetat" ? response.data.contested_number[idx] : undefined, // Exclude contested data if endpoint is "cetat"
            contested_jurisdiction: endpoint !== "search_cetat" ? response.data.contested_jurisdiction[idx] : undefined, // Exclude contested data if endpoint is "cetat"
          })).filter(result => result.ID && result.title); // Ensure no key data is null or undefined
          console.log(this.searchResults);
        } else {
          console.log(response.data.IDs)
          console.log(response.data.Questions)
          console.error('Invalid or incomplete response structure:', response.data);
          alert('A problem occurred with the response from the server.');
        }

        this.$nextTick(() => {
          this.scrollToFirstResult();
        });

      } catch (error) {
        if (error.response && error.response.status === 429) {
          this.searchError = true;
          this.errorMessage = 'Vous avez dépassé le nombre de requêtes autorisées sans abonnement. Veuillez vous abonner pour continuer à utiliser le service.';
          this.checkUserSubscriptionStatus();
          // this.fetchProducts();
        } else {
          console.error('Error while fetching data:', error);
          this.errorMessage = 'Erreur lors de la récupération des données.';
          this.searchError = true;
        }
      } finally {
        this.isLoading = false; // Stop loading
      }
    },

    async signOut() {
      try {
        await logout();
        this.$router.replace({ path: '/' });
      } catch (error) {
        alert(error.message); // Consider using a more user-friendly error handling approach
      }
    },

    changePage(step) {
      const nextPage = this.currentPage + step;
      if (nextPage > 0 && nextPage <= this.totalPages) {
        this.currentPage = nextPage;
        // Optionally, scroll to top of the results or perform other UI adjustments
      }
    },
  },
};
</script>

<style scoped>

.contested-case-card {
  margin-top: 30px;
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  text-align: center; /* Aligns text to the center */
  height: 100%; /* Ensures that flexbox centers content vertically */
}

.previous-searches .scrollable-checkbox-container {
  margin-top: 45px; /* Space between logo and checkboxes */
  background-color: #f8f9fa; /* Light grey background */
  max-height: 160px; /* Maximum height before scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Full width of the container */
  padding: 10px; /* Padding inside the container */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.previous-searches .form-check-label {
  color: #333; /* Dark text for better visibility on light grey */
  font-size: 0.9rem; /* Appropriate font size */
  margin-left: 0.5rem; /* Space between checkbox and label */
}

.previous-searches .form-check-input {
  vertical-align: middle;
  margin-top: 0.3rem; /* Adjust vertical alignment */
}

.previous-searches .form-check {
  margin-bottom: 10px; /* Spacing between checkboxes */
}

.buttons-class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 80%;
}

.error-input {
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.6); /* Red border for error inputs */
}

.error-message {
  color: lightcoral; /* Red text for error message */
  font-size: 17px; /* Font size for error message */
  margin-top: 25px; /* Space between input and error message */
  text-align: center; /* Center the error message */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s, background-color 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.example-query button {
  background-color: #f4f4f4;
  color: black;
  border: none;
  padding: 10px 15px;
  border-radius: 50px; /* Increased to make the button very rounded */
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.example-query button:hover {
  background-color: lightgray;
}

.small-arrow {
  font-size: 10px;  /* Set a smaller font size for the arrow */
  vertical-align: 1px;
}

.summary-toggle-button {
  font-size: 15px;  /* Slightly larger font size */
  margin-top: 12px; /* Margin top */
}

#law-input-container {
  width: 90%;
  margin-top: 20px;
}

.case-details p {
    margin-bottom: 10px;  /* Adds space below each paragraph */
}

/* Optionally, you can add top margin to space it from any preceding content */
.case-details {
    margin-top: 20px;
}

.form-chambre {
  display: block;  /* Default display */
  width: 100%; /* Full width */
  padding: 0.6em 0.8em; /* Vertical and horizontal padding */
  border: 1px solid #ccc; /* Border to match your style */
  border-radius: 4px; /* Rounded corners */
  background-color: #f4f4f4; /* Background color */
  line-height: 1.25; /* Adjust line height to ensure vertical centering */
  height: auto; /* Let the height grow with content and padding */
  appearance: none; /* Remove default system styling */
  font-weight: 500;
  margin-bottom: 5px;

}

.form-chambre {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%206l6%206%206-6z%22%20fill%3D%22%23007BFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

/* Adjust the focus state to improve usability */
.form-chambre:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}


.selected-law-tag {
  background-color: black; /* Primary blue, can adjust to match theme */
  color: white;
  padding: 4px 8px;
  font-size: 0.75rem; /* Smaller font size for compact tags */
  border-radius: 12px; /* Rounded corners for a smoother look */
  margin: 2px; /* Smaller margin for tight layout */
  display: flex;
  align-items: center;
  height: 20px; /* Fixed height for uniformity */
}

.selected-law-tag span {
  margin-left: 6px;
  cursor: pointer;
  font-size: 0.85rem; /* Slightly larger to make it easier to click */
}

.cursor-pointer {
  cursor: pointer;
}

.mobile-header {
  display: none;
}

.small-card {
  font-size: 0.8rem; /* Smaller font size */
  padding: 0.25rem 0.5rem; /* Smaller padding for more compact look */
}

.previous-searches {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: 100%;
  border-right: 1px solid #ccc;
  padding-top: 20px;
  overflow-y: auto;
  background-color: #1c1c1a;
  position: sticky;
  top: 0; /* This line ensures the div sticks to the top of the viewport */
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.result-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start of the container */
}

.result-item {
  padding: 20px;
  margin-bottom: 15px; /* Increase space between items */
}


.result-text {
  font-weight: bold; /* Make the result text bold */
}

.result-link {
  color: #007bff; /* Blue link color */
  text-decoration: none; /* Remove underline */
}

.result-link:hover {
  text-decoration: underline; /* Underline on hover */
}

#titles {
  align-self: center; /* Larger font size for titles */
  margin: 6px;
}

.search-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.input-container {
  display: flex;
}

.search-input {
  width: 150vw; /* Adjust the width to 70% and leave space for the margin */
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f4f4f4;
  box-sizing: border-box;
  resize: none; /* Prevent resizing */
}

.solution-select {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  overflow-y: auto; /* Allow scrolling if there are many options */
}

.search-box {
  height: 60vh;
  margin: 0 auto;
  width: 90%;
  padding: 20px;
}

.search-button {
  background-color: #1c1c1a; /* Dark green background */
  color: white; /* White text */
  padding: 12px 20px; /* Padding for a larger button */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Hand cursor on hover */
  font-size: 16px; /* Larger font size */
  margin-top: 5px; /* Margin at the top */
  transition: background-color 0.5s ease;
  position: relative; /* Needed for the gradient effect positioning */
  overflow: hidden; /* Keeps the gradient within the button boundaries */
}

.search-button:hover {
  background-color: lightgray; /* Lighter gray on hover */
}

.search-button.loading {
  color: transparent; /* Make text color transparent when loading */
  background: linear-gradient(to left, lightgray 50%, black 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  animation: slide 3s linear infinite;
  cursor: default; /* Change cursor to default to indicate it's not clickable */
  pointer-events: none; /* Prevents interactions while loading */
}

@keyframes slide {
  to {
    background-position: -100%;
  }
}

.disconnect-button {
  background-color: #f4f4f4;
  font-size: 0.9rem;
  color: #1c1c1a;
  padding: 15px 20px; /* Padding */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Hand cursor on hover */
  align-self: center; /* Center button horizontally */
  margin-bottom: 5px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  width: 100%;
}

#sub-but:hover {
  background-color: white; /* Darker shade of grey on hover */
  transform: scale(1.1); /* Increase size on hover */
  box-shadow: 0 0 10px 0 white; /* Add white shadow on hover */
}

#disco {
  background-color: #1c1c1a;
  color: #f4f4f4;
}

#disco:hover {
  background-color: grey; /* Darker shade of red on hover */
}

#gerer {
  background-color: #1c1c1a;
  color: #f4f4f4;
}

#gerer:hover {
  background-color: grey; /* Darker shade of red on hover */
}

.search-results {
  width: 80%;
  margin-top: 30px;
  font-family: sourcesanspro, arial, sans-serif;
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding inside the results box */
}

.result-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ccc;
  background-color: #fff; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  margin-bottom: 10px; /* Space between items */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effects */
}

.result-item:hover {
  transform: translateY(-4px); /* Slight lift effect */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Enhanced shadow on hover */
}

.result-title {
  margin: 0 0 8px 0; /* Adjust spacing around the title */
  color: #333; /* Dark color for contrast */
  font-size: 1.25rem; /* Larger font size for titles */
}

.result-link {
  color: #007bff; /* Keep the blue link color */
  text-decoration: none; /* No underline */
  font-weight: bold; /* Make links bold for emphasis */
}

.result-link:hover {
  text-decoration: underline; /* Underline on hover for feedback */
}

.checkboxes-and-date-container {
    width: 50%;
  }

.solution-select {
  width: 100%;
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.date-range-selection {
  padding: 8px;
}

.form-control {
}

#input-2 {
  margin-top: 5px;
}

.list-enter-active, .list-leave-active {
  transition: all 0.5s ease;
}

.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.pagination-controls button {
  padding: 5px 15px;
  margin: 0 10px;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3; /* Darken button on hover */
}

.pagination-controls button:disabled {
  background-color: #cccccc; /* Light gray for disabled state */
  cursor: not-allowed;
}

.pagination-controls span {
  color: #333; /* Dark gray color for text */
  font-weight: bold;
}

.main-container {
  background-color: white; /* Light background for visibility */
  display: flex;
  height: 100%;
}

.select-container {
  margin-top: 20px; /* Space below the select dropdown */
}

.form-control {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Ensure it matches the input fields in width */
}




@media (max-width: 768px) {
  .previous-searches {
    display: none;
  }

  .mobile-header {
    display: flex; /* Enable display for mobile header */
    width:100% ;
    justify-content: space-between; /* Space between logo and button */
    align-items: center; /* Center items vertically */
    width: 100%; /* Full width of the viewport */
    padding: 10px 20px; /* Padding for breathing space */
    background-color: #f5f5f5; /* Light background for visibility */
  }

  .disconnect-button-mobile {
    padding: 8px 16px;
    background-color: black; /* Consistent with other button styles */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .scrollable-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }

  .scrollable-container {
    width: 100%; /* Full width to utilize the screen space */
    padding: 20px; /* Adjust padding for smaller screens */
    flex-direction: column;
    overflow-y: visible; /* Adjust overflow properties if needed */
    height: 150vh;
  }

  .input-container {
    flex-direction: column; /* Stack elements vertically in the input container */
  }

  .checkboxes-and-date-container {
    width: 100%; /* Full width to accommodate smaller screens */
    display: flex;
    flex-direction: column; /* Stack checkbox and date inputs vertically */
  }

  .search-input {
    width: 100%; /* Adjust width for mobile */
    min-height: 100px; /* Adjust the height to be more accommodating for text input */
  }

  .search-results {
    margin-top: 20px; /* Reduce margin top for tighter mobile layout */
  }

  .arrow-up::before {
    content: '\25B2'; /* Unicode upwards arrow */
  }
  .arrow-down::before {
    content: '\25BC'; /* Unicode downwards arrow */
  }

  .main-container {
    display: block;
    height: 100%;
  }

  #check-container {
    width: 65vw;
    margin-top: 10px;
    display: none;
  }

  #date-container {
    width: 100%;
  }

  .search-box {
    height: 100%;
    margin: 0 auto;
    width: 90%;
    padding: 20px;
  }

  .search-button {
    margin-top: 10px;
  }

}


/* Add the rest of your CSS here, similar to how .search-input and .search-box were added. */
</style>
